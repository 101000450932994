import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import reducer from './store/reducer/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import inventory_id from "./translations/id/inventory.json";
import inventory_en from "./translations/en/inventory.json";
import dealer_id from "./translations/id/dealer.json";
import dealer_en from "./translations/en/dealer.json";
import lead_id from "./translations/id/lead.json";
import lead_en from "./translations/en/lead.json";
import qc_id from "./translations/id/qc.json";
import qc_en from "./translations/en/qc.json";
import common_th from "./translations/th/common.json";
import inventory_th from "./translations/th/inventory.json";
import dealer_th from "./translations/th/dealer.json";
import lead_th from "./translations/th/lead.json";
import qc_th from "./translations/th/qc.json";
import secureStorage from './config/encrypt';

i18next.init({
    interpolation: { escapeValue: false },
    lng: (secureStorage.getItem('lang') == 'id')? 'id': 'en',
    resources: {
        en: {
            common: common_en,
            inventory: inventory_en,
            dealers:dealer_en,
            lead: lead_en,
            qc:qc_en
        },
        id: {
            common: common_id,
            inventory: inventory_id,
            dealers:dealer_id,
            lead: lead_id,
            qc:qc_id
        },
        th: {
            common: common_th,
            inventory: inventory_th,
            dealers:dealer_th,
            lead: lead_th,
            qc:qc_th
        },
    },
});


const store = createStore(reducer, window.devToolsExtension && window.devToolsExtension());

ReactDOM.render(<I18nextProvider i18n={i18next}><Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider></I18nextProvider>, document.getElementById('root'));
serviceWorker.unregister();
