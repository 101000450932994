import React, { useState, useEffect } from 'react';
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';

const LoginOtpValidation = ({ t, onChange, onClick, resendOTP, errorMessage }) => {
  // const [reloadCaptcha, setReloadCaptcha] = useState(1);
  const [otpTimer, setOtpTimer] = useState(60);
  const [otp,setOtp] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOtpTimer((prevOtpTimer) => prevOtpTimer - 1);
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  const handleChange = (event) => {
    setOtp(event.target.value)
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const validateOTP = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const handleResend = async (event) => {
    setOtp("")
    await resendOTP(event);
    setOtpTimer(60);
  };

  return (
    <form method="post" id="validate-otp-form1" className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
      <InputField
        inputProps={{
          id: "otp",
          type: "tel",
          name: "otp",
          label: t('forgot.otp'),
          value: otp,
          dataerror: errorMessage.otp,
        }}
        onChange={handleChange}
      />
      {otpTimer > 0 ? (
        <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span>
      ) : (
        <span className="otp-time" style={{ cursor: "pointer" }} onClick={handleResend}>
          Resend
        </span>
      )}
      <div className="form-group">
        <a href="#" className="editmobile hidden" id="inv-otp">{t('forgot.edit')}</a>
        <span id="validateOtp" className="valid-otp"></span>
      </div>
      <div className="form-group" id="showbutton1">
        <button type="submit" className="btn btn-primary btn-lg btn-block" id="otp-login" onClick={validateOTP}>
          {t('forgot.validate_otp')}
        </button>
      </div>
    </form>
  );
};

export default withTranslation('common')(LoginOtpValidation);
